import { createContext, useContext } from "react";
import { WindowSizeState } from "./windowSizeHook";

export enum ViewpointSize {
    BigScreen = 'BigScreen',
    Laptop = 'Laptop',
    Tablet = 'Tablet',
    Mobile = 'Mobile'
};

type ViewpointSizesDict = {
    [K in ViewpointSize]: number;
};


export const ViewpointSizes: ViewpointSizesDict = {
    [ViewpointSize.BigScreen]: 1600,
    [ViewpointSize.Laptop]: 1200,
    [ViewpointSize.Tablet]: 992,
    [ViewpointSize.Mobile]: 430,
}

export const CSSBigScreenOrBigger = `@media (min-width: ${ViewpointSizes[ViewpointSize.BigScreen]}px)`;
export const CSSLaptopOrBigger = `@media (min-width: ${ViewpointSizes[ViewpointSize.Laptop]}px)`;
export const CSSTabletOrBigger = `@media (min-width: ${ViewpointSizes[ViewpointSize.Tablet]}px)`;
export const CSSMobileOrBigger = `@media (min-width: ${ViewpointSizes[ViewpointSize.Mobile]}px)`;

export const CSSLaptopOrSmaller = `@media (max-width: ${ViewpointSizes[ViewpointSize.BigScreen] - 1}px)`;
export const CSSTabletOrSmaller = `@media (max-width: ${ViewpointSizes[ViewpointSize.Laptop] - 1}px)`;
export const CSSMobileOrSmaller = `@media (max-width: ${ViewpointSizes[ViewpointSize.Tablet] - 1}px)`;
export const CSSSmallerThanMobile = `@media (max-width: ${ViewpointSizes[ViewpointSize.Mobile] - 1}px)`;

export const CSS0ToMobile = `@media (max-width: ${ViewpointSizes[ViewpointSize.Mobile] - 1}px)`;
export const CSS1MobileToTablet = `@media (min-width: ${ViewpointSizes[ViewpointSize.Mobile]}px) and (max-width: ${ViewpointSizes[ViewpointSize.Tablet] - 1}px)`;
export const CSS2TableToLaptop = `@media (min-width: ${ViewpointSizes[ViewpointSize.Tablet]}px) and (max-width: ${ViewpointSizes[ViewpointSize.Laptop] - 1}px)`;
export const CSS3LaptopToBigScreen = `@media (min-width: ${ViewpointSizes[ViewpointSize.Laptop]}px) and (max-width: ${ViewpointSizes[ViewpointSize.BigScreen] - 1}px)`;
export const CSS4BigScreen = `@media (min-width: ${ViewpointSizes[ViewpointSize.BigScreen]}px)`;


export const ViewpointSizesOrder: ViewpointSize[] = [
    ViewpointSize.Mobile,
    ViewpointSize.Tablet,
    ViewpointSize.Laptop,
    ViewpointSize.BigScreen
]

export const getMinWidth = (size: ViewpointSize): number => {
    return ViewpointSizes[size];
}

export const getEffectiveSizeFromWidth = (
    width: number | undefined
): ViewpointSize => {
    if (!width) {
        return ViewpointSize.Mobile;
    }

    let ret = ViewpointSize.Mobile;
    for (let sizeName in ViewpointSizesOrder) {
        if (getMinWidth(ViewpointSizesOrder[sizeName]) <= width) {
            ret = ViewpointSizesOrder[sizeName];
        }
    }

    return ret;
}


export const isWidthFor = (
    width: number | undefined,
    size: ViewpointSize
): boolean => {
    return !!width && width >= getMinWidth(size);
}

/**
 * Checks if current size is big enough for target device
 * @param current 
 * @param target 
 * @returns 
 */
export const isSizeFor = (
    current: WindowSizeState | undefined,
    target: ViewpointSize
): boolean => {
    return !!current && !!current.width && current.width >= getMinWidth(target);
}

type ViewpointValuesDict<U> = {
    [K in ViewpointSize]: U;
};

export function getValueForSize<U>(
    values: ViewpointValuesDict<U>,
    default_value: U,
    current: WindowSizeState | undefined,
): U {
    if (!current || !current.width) {
        return default_value;
    }

    let ret = default_value;
    for (let sizeName in ViewpointSizesOrder) {
        if (getMinWidth(ViewpointSizesOrder[sizeName]) <= current.width) {
            ret = values[ViewpointSizesOrder[sizeName]];
        }
    }

    return ret;
}

export function getValueForViewpoint<U>(
    values: ViewpointValuesDict<U>,
    default_value: U,
    current: ViewpointSize,
): U {
    return values[current] || default_value;
}

export const EffectiveSizeContext = createContext<ViewpointSize>(ViewpointSize.Mobile);

export function useValueForSize<U>(
    values: ViewpointValuesDict<U>,
    default_value: U,
): U {
    const current = useContext(EffectiveSizeContext);
    return getValueForViewpoint(values, default_value, current);
}