import React, { FC, Suspense, lazy } from "react";
import { PageLoading } from "./features/shared/components/PageLoading/PageLoading";
import ErrorBoundary from "./features/shared/components/PageError/PageError";
import { ConfigProvider } from "antd";
import locale from 'antd/locale/en_US';


export const ApplicationWrapper = lazy(() => import("./ApplicationWrapper"));

export const Application: FC = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <Suspense fallback={<PageLoading fullHeight={true} />}>
                    <ConfigProvider locale={locale}>
                        <ApplicationWrapper />
                    </ConfigProvider>
                </Suspense>
            </ErrorBoundary>
        </React.StrictMode>
    );
};