import { useEmotionCss } from '@ant-design/use-emotion-css';
import Lottie from 'react-lottie';
import * as animationData from './problem.json'
import { CSS0ToMobile, CSS4BigScreen } from '../../../../sharedStyles/mediaQueries';
import React, { ErrorInfo } from 'react';
import * as Sentry from "@sentry/react";

export interface PageErrorProps {
    fullHeight?: boolean
    error?: any
}

export const PageError: React.FC<PageErrorProps> = (props) => {
    const style = useEmotionCss(({ token }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: props.fullHeight === true ? '100vh' : '100%',

        '& > .inner': {
            width: '60vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            [CSS0ToMobile]: {
                width: '80vw',
            },
            [CSS4BigScreen]: {
                width: '400px',
            },
        },

        '.text': {
            fontSize: token.fontSizeHeading3,
            fontFamily: token.fontFamily,
            textAlign: 'center',
        }
    }));

    return <div className={style}>
        <div className='inner'>
            <Lottie
                isPaused={false}
                isStopped={false}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMaxYMid meet'
                    }
                }}
            />
            <div className='text'>
                Something went wrong, but don't worry, we're on it!
            </div>
        </div>
    </div>
}

export interface ErrorBoundaryProps {
    fallback?: React.ReactNode;
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Fatal error caught by ErrorBoundary', error, errorInfo);
        Sentry.captureException(error, {
            extra: {
                componentStack: errorInfo.componentStack,
            },
            tags: {
                'fatal': 'true',
            }
        });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback === undefined) {
                return <PageError fullHeight={true} />
            }

            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}
