import { useEmotionCss } from '@ant-design/use-emotion-css';
import Lottie from 'react-lottie';
import * as animationData from './animation_llsvyfrn.json'
import { CSS0ToMobile, CSS4BigScreen } from '../../../../sharedStyles/mediaQueries';
import { useEffect, useState } from 'react';

export interface PageLoadingProps {
    fullHeight?: boolean
}

export const PageLoading: React.FC<PageLoadingProps> = (props) => {
    const [startAnimation, setStartAnimation] = useState(false);

    // 100ms delayed animation
    useEffect(() => {
        const timeout = setTimeout(() => {
            setStartAnimation(true);
        }, 300);

        return () => clearTimeout(timeout);
    })
    
    const style = useEmotionCss(({ token }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: props.fullHeight === true ? '100vh' : '100%',

        '& > .inner': {
            width: '60vw',
            height: '60vh',

            [CSS0ToMobile]: {
                width: '80vw',
                height: '80vh',
            },
            [CSS4BigScreen]: {
                width: '400px',
                height: '400px',
            },
        }
    }));

    return <div className={style}>
        <div className='inner'>
            <Lottie
                isPaused={!startAnimation}
                isStopped={!startAnimation}
                style={{ visibility: startAnimation ? 'visible' : 'hidden' }}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid meet'
                    }
                }}
            />
        </div>
    </div>
}